import React from "react";
import { BasicMatch, ClientExtended, CouplesMatch } from "../../../api/types";

import {
  AlertFilled,
  ClockCircleFilled,
  CloseCircleOutlined,
  RetweetOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { State } from "react-stateface";
import { Tooltip } from "antd";
import * as colors from "../../../assets/colors";
import { Flex, Row } from "../../_layout/Flex";
import { styledStitches } from "@/app/design-system/styles/stitches.config";

// Define allowed service states and their colors in a single object
const SERVICE_STATE_COLORS: Record<string, string> = {
  AL: "#A32638", // Crimson Tide
  AK: "#002147", // Midnight Blue (Alaska Flag)
  AZ: "#CC0033", // Arizona Wildcats
  AR: "#9D2235", // Arkansas Razorbacks
  CA: "#FDB515", // Go Bears (UC Berkeley)
  CO: "#1E4D2B", // Colorado State Rams
  CT: "#0074A8", // UConn Huskies
  DE: "#FFB81C", // Delaware Blue Hens
  FL: "#782F40", // Florida State Garnet
  GA: "#BA0C2F", // Georgia Bulldogs
  HI: "#008E97", // Hawaii Rainbow Warriors
  ID: "#0033A0", // Boise State Blue
  IL: "#E84A27", // Illinois Fighting Illini
  IN: "#990000", // Indiana Hoosiers
  IA: "#FFC72C", // Iowa Hawkeyes
  KS: "#0051BA", // Kansas Jayhawks
  KY: "#0033A0", // Kentucky Wildcats
  LA: "#582C83", // LSU Tigers
  ME: "#003C71", // Maine Blue
  MD: "#E03C31", // Maryland Terrapins (Red)
  MA: "#900020", // Massachusetts Minutemen
  MI: "#00274C", // Michigan Wolverines
  MN: "#7A0019", // Minnesota Gophers
  MS: "#720E0F", // Mississippi State Maroon
  MO: "#F1B82D", // Missouri Tigers
  MT: "#004B87", // Montana State Bobcats
  NE: "#E41C38", // Nebraska Cornhuskers
  NV: "#63666A", // Nevada Wolf Pack
  NH: "#CC0000", // New Hampshire Wildcats
  NJ: "#CC0033", // Rutgers Scarlet Knights
  NM: "#FFD700", // New Mexico Lobos (Gold)
  NY: "#7399C6", // Go Goldman Sachs (Blue)
  NC: "#7BAFD4", // UNC Tar Heels
  ND: "#006A4D", // North Dakota Fighting Hawks
  OH: "#BB0000", // Ohio State Buckeyes
  OK: "#841617", // Oklahoma Sooners
  OR: "#154733", // Oregon Ducks
  PA: "#011F5B", // Penn State Lions
  RI: "#F7E7CE", // Rhode Island (Yellow Beige)
  SC: "#73000A", // Go Gamecocks
  SD: "#0088CE", // South Dakota Coyotes
  TN: "#FF8200", // Tennessee Volunteers
  TX: "#BF5700", // Go Longhorns
  UT: "#CC0033", // Utah Utes
  VT: "#00563F", // Vermont Catamounts
  VA: "#232D4B", // Go Hoos (Virginia)
  WA: "#008467", // Washington State
  WV: "#EAAA00", // West Virginia Mountaineers
  WI: "#C5050C", // Wisconsin Badgers
  WY: "#FFCC00", // Wyoming Cowboys
};

// Extract the allowed states from the object keys
const ALLOWED_SERVICE_STATES = Object.keys(SERVICE_STATE_COLORS);

const ServiceStateIconContainer = styledStitches("div", {
  $$stateColor: colors.$text,

  fontSize: "8pt",
  display: "flex",
  color: colors.$text,
  margin: "0 8px",
  [`& .react-stateface-container`]: {
    display: "inline-block",
    width: "12px",
    marginRight: "4px",
  },
  "& svg": {
    verticalAlign: "middle",
    fill: "$$stateColor",
  },
  // Dynamically generate state variants from the SERVICE_STATE_COLORS object
  variants: {
    state: Object.fromEntries(
      ALLOWED_SERVICE_STATES.map((state) => [
        state,
        { $$stateColor: SERVICE_STATE_COLORS[state] },
      ]),
    ),
  },
  defaultVariants: {
    state: "none",
  },
});

export const ServiceStateIcon: React.FC<{ state: string }> = ({ state }) => {
  if (!ALLOWED_SERVICE_STATES.includes(state)) {
    return null;
  }

  return (
    <ServiceStateIconContainer state={state} key={state}>
      <State>{state}</State>
      <span>{state}</span>
    </ServiceStateIconContainer>
  );
};

const PayerTagContainer = styledStitches("div", {
  fontSize: "8pt",
  verticalAlign: "middle",
  marginRight: "4px",
});

export const PayerTag = ({ payer }: { payer: string }) => {
  if (!payer) return null;
  const payerString = payer.replace(/[^A-Z]+/g, ""); // Extract uppercase letters
  return (
    <Tooltip title={payer} key={payer}>
      <PayerTagContainer>{payerString}</PayerTagContainer>
    </Tooltip>
  );
};

export const StaticMatchIcons = ({
  basicMatch,
}: {
  basicMatch: BasicMatch | CouplesMatch;
}) => {
  const primaryClient: ClientExtended =
    basicMatch.client || basicMatch.couple?.clientA;
  const payers = primaryClient.payers?.map((p) => p.payer.display_name) || [];

  return (
    <div
      style={{
        flex: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "center",
      }}
    >
      <Flex>
        {primaryClient.service_state && (
          <ServiceStateIcon state={primaryClient.service_state} />
        )}
      </Flex>
      <Row>
        {payers.map((payer) => (
          <PayerTag payer={payer} key={payer} />
        ))}
      </Row>
    </div>
  );
};

export const MatchIcons = ({ basicMatch }: { basicMatch: BasicMatch }) => {
  return (
    <Row layout="start center" style={{ width: "60px" }}>
      <Flex>
        {basicMatch.priority === "high_acuity" && (
          <Tooltip placement="top" title={"High Acuity Client"}>
            <AlertFilled style={{ color: colors.$red }} />
          </Tooltip>
        )}
        {basicMatch.priority === "high_priority" && (
          <Tooltip placement="top" title={"High Priority Client"}>
            <ClockCircleFilled style={{ color: colors.$yellow }} />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {basicMatch.returning_to_same_clinician && (
          <Tooltip placement="top" title={"Returning to Same Clinician"}>
            <UsergroupAddOutlined style={{ color: colors.$blue }} />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {basicMatch.is_rematch && (
          <Tooltip placement="top" title={"Rematch"}>
            <RetweetOutlined style={{ color: colors.$green }} />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {basicMatch.former_client && (
          <Tooltip placement="top" title={"Former Client"}>
            <UserOutlined style={{ color: colors.$night }} />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {basicMatch.is_currently_awaiting_eligibility_benefits_verification && (
          <Tooltip placement="top" title={"Awaiting E&B"}>
            <CloseCircleOutlined style={{ color: colors.$red }} />
          </Tooltip>
        )}
      </Flex>
    </Row>
  );
};
