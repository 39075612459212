import { useQuery } from "react-query";
import api from "@/api";
import { FIVE_MINUTES } from "@/app/_helpers/constants";

export type ClinicianSchedulePreference = {
  clinician_id: number;
  target_scheduled_sessions: number;
  default_target: number;
  target_scheduled_consults: number;
  default_consult_target: number;
  change_reason: string;
  change_reason_other: string;
  change_source: string;
  modified_at: string;
  changed_by_user: string | null;
};

export enum clientSchedulerTypeEnum {
  FLEX = "flex",
  THERAPY = "therapy",
  CONSULT = "consult",
  PSYCHIATRY = "psychiatry",
}

export interface ClinicianSchedulerData {
  schedule_preference: ClinicianSchedulePreference;
  scheduler_type: clientSchedulerTypeEnum;
}

type ClientSchedulerTeamResponse = Record<number, ClinicianSchedulerData>;

const getClientSchedulerTeamPreferences =
  async (): Promise<ClientSchedulerTeamResponse> => {
    try {
      const { data } = await api.get(`/api/client_scheduler_preferences/`);
      return data;
    } catch (e) {
      throw new Error(
        `[getClientSchedulerTeamPreferences] Unable to fetch clinician schedule preference.`,
        { cause: e as Error },
      );
    }
  };

export const useGetClientSchedulerTeamPreferences = () => {
  return useQuery<ClientSchedulerTeamResponse>(
    ["ClientSchedulerTeamPreferences"],
    () => getClientSchedulerTeamPreferences(),
    {
      staleTime: FIVE_MINUTES,
    },
  );
};
