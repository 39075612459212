import { CurrentUser } from "@/api/types";
import { UserHasAnyPermissions } from "@/app/_helpers/permissions";
import { useShallowEqualSelector } from "@/app/_helpers/redux";

export function useAppPermissions() {
  const { isAuthenticated, currentUser } = useShallowEqualSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.currentUser,
  }));

  const currentUserClinician = useShallowEqualSelector(() => {
    if (currentUser?.clinician) {
      return currentUser?.clinician;
    }
    return null;
  });

  const {
    hasContentLibraryPermissions,
    hasMyConsultsPermissions,
    hasMatchQueuePermissions,
    hasTeamPermissions,
    hasTimeOffPermissions,
    hasAvailabilityToolPermissions,
    hasMyClientsPermissions,
    hasConsentSurveyPermissions,
    hasAQMTuningPermissions,
    hasShoppingCartPermissions,
    hasMatchAdminToolPermissions,
    hasGroupTherapyPermissions,
    hasCogsworthPermissions,
  } = getPermissions(currentUser);

  return {
    isAuthenticated,
    currentUserClinician,
    hasContentLibraryPermissions,
    hasMyConsultsPermissions,
    hasMatchQueuePermissions,
    hasTeamPermissions,
    hasTimeOffPermissions,
    hasAvailabilityToolPermissions,
    hasMyClientsPermissions,
    hasConsentSurveyPermissions,
    hasAQMTuningPermissions,
    hasShoppingCartPermissions,
    hasMatchAdminToolPermissions,
    hasGroupTherapyPermissions,
    hasCogsworthPermissions,
  };
}

function getPermissions(currentUser: CurrentUser | null) {
  const hasContentLibraryPermissions = UserHasAnyPermissions(currentUser, [
    // TODO: replace IsResourceLibBetaUser with IsClinician post rollout
    "IsResourceLibBetaUser",
    "IsResourceLibCurator",
  ]);

  const hasMyConsultsPermissions = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
    "IsConsultClinician",
    "IsRequeuer",
    "IsPsychiatrist",
  ]);

  const hasMatchQueuePermissions = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
    "IsMatchingAdmin",
  ]);

  const hasTimeOffPermissions = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
    "IsMatchingAdmin",
    "IsClinicalLeader",
    "IsCareCoordinator",
  ]);

  const hasTeamPermissions = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
    "IsMatchingAdmin",
    "IsClinicalLeader",
  ]);

  const hasAvailabilityToolPermissions = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
    "IsATSlotEditor",
  ]);

  const hasMyClientsPermissions = UserHasAnyPermissions(currentUser, [
    "IsMyClientsWriteAllowed",
    "IsClinician",
    "IsClinicalLeader",
    "IsSuperUser",
    "IsCareCoordinator",
  ]);

  const hasConsentSurveyPermissions = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
    "IsCouplesClinician",
  ]);

  const hasAQMTuningPermissions = UserHasAnyPermissions(currentUser, [
    "IsMatchingAdmin",
  ]);
  const hasShoppingCartPermissions = UserHasAnyPermissions(currentUser, [
    "IsMatchingAdmin",
  ]);

  const hasMatchAdminToolPermissions = UserHasAnyPermissions(currentUser, [
    "IsSuperUser",
  ]);

  const hasGroupTherapyPermissions = UserHasAnyPermissions(currentUser, [
    "IsGroupTherapyUser",
    "IsSuperUser",
  ]);

  const hasCogsworthPermissions = UserHasAnyPermissions(currentUser, [
    "IsCogsworthBetaUser",
  ]);

  return {
    hasContentLibraryPermissions,
    hasMyConsultsPermissions,
    hasMatchQueuePermissions,
    hasTimeOffPermissions,
    hasTeamPermissions,
    hasAvailabilityToolPermissions,
    hasMyClientsPermissions,
    hasConsentSurveyPermissions,
    hasAQMTuningPermissions,
    hasShoppingCartPermissions,
    hasMatchAdminToolPermissions,
    hasGroupTherapyPermissions,
    hasCogsworthPermissions,
  };
}
