import React, { useMemo, useContext } from "react";
import { CurrentUser, Config } from "../../api/types";
import { useShallowEqualSelector } from "./redux";

type UserPermissionFn = (user: CurrentUser | null, config?: Config) => boolean;

const userIsPsychiatrist: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Psychiatrist");

const userIsSuperUser: UserPermissionFn = (user) =>
  !!user && !!user.isSuperUser;

const userIsNetworkClinician: UserPermissionFn = (user) =>
  !!user && !!user.clinician?.is_network;

const userIsClinicalLeader: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Clinical Leader");

const userIsConsultClinician: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Consult Clinician");

const userIsCouplesClinician: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Couples Clinician");

const userIsPanManAdmin: UserPermissionFn = (user) =>
  !!user && (user.isSuperUser || user.groups.includes("Clinical Leader"));

const userIsMatchingAdmin: UserPermissionFn = (user) =>
  !!user && (user.isSuperUser || user.groups.includes("Matching Admin"));

const userIsMBCProgramBetaUser: UserPermissionFn = (user) =>
  !!user && (user.isSuperUser || user.groups.includes("MBC Program Beta User"));

const userIsMBCDataAlphaUser: UserPermissionFn = (user) =>
  !!user && (user.isSuperUser || user.groups.includes("MBC Data Alpha User"));

const userIsMDBTeamViewUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("MDB Team View User");

const userIsATSlotEditor: UserPermissionFn = (user) =>
  !!user &&
  (userIsATMatchRequestEditor(user) ||
    user.groups.includes("Availability Tool Slot Editor (1)"));

const userIsATMatchRequestEditor: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Availability Tool Match Request Editor (2)");

const userIsConsultTimeslotEditor: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Consult Timeslot Editor");

const userIsWheelCWAUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Wheel CWA User");

const userIsCogsworthBetaUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Cogsworth Beta");

const userIsCogsworthOpsUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Ops Edit");

const userIsFlexSchedulingUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Flex Scheduling User");
// TODO: remove IsResourceLibBetaUser post rollout
const userIsResourceLibBetaUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Resource Library - Beta User");

const userIsResourceLibCurator: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Resource Library - Curator");

const userIsClinician: UserPermissionFn = (user) => !!user && !!user.clinician;

const userIsRequeuer: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Requeuer");

const userIsSchedulingUIBetaUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Scheduling UI - Beta User");

const userIsMyClientsWriteAllowedUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Scheduling UI - Writes Allowed");

const userIsQualityBetaUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Quality UI - Beta User");

const userIsQualityBetaSurveyUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Quality - Survey Beta");

const userIsGroupTherapyUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Group Therapy User");

const userIsGroupTherapyV2User: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Group Therapy V2 User");

const userIsCareCoordinator: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Care Coordinator");

const userIsCareEpisodesUser: UserPermissionFn = (user) => true;

const userIsDashboardV2User: UserPermissionFn = (user) => true;

const userIsMHQOLSurveyUser: UserPermissionFn = (user, config?: Config) =>
  (!!user && user.groups.includes("MHQOL Survey - Beta User")) ||
  !!config?.mhqol_enabled;

const userIsAvailabilityToolEndDateUser: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Availability Tool End Date User (4)");

const userIsCareAlertBetaUser: UserPermissionFn = (user) => true;
const userIsHolisticBetaUser: UserPermissionFn = (user, config?: Config) =>
  (!!user && user.groups.includes("Holistic Alerts - Beta User")) ||
  !!config?.roofdog_enabled;

const userCanTrainingHoursTimeOff: UserPermissionFn = (user) =>
  !!user && user.groups.includes("2024-07: Training Hours TimeOff User");

const userConsultConsents: UserPermissionFn = (user) =>
  !!user && user.groups.includes("Consult Consent");


export const Permissions = {
  IsPsychiatrist: userIsPsychiatrist,
  IsConsultClinician: userIsConsultClinician,
  IsCouplesClinician: userIsCouplesClinician,
  IsSuperUser: userIsSuperUser,
  IsClinicalLeader: userIsClinicalLeader,
  IsPanManAdmin: userIsPanManAdmin,
  IsMatchingAdmin: userIsMatchingAdmin,
  IsMBCProgramBetaUser: userIsMBCProgramBetaUser,
  IsMBCDataAlphaUser: userIsMBCDataAlphaUser,
  IsMDBTeamViewUser: userIsMDBTeamViewUser,
  IsATSlotEditor: userIsATSlotEditor,
  IsATMatchRequestEditor: userIsATMatchRequestEditor,
  IsConsultTimeslotEditor: userIsConsultTimeslotEditor,
  IsWheelCWAUser: userIsWheelCWAUser,
  IsCogsworthBetaUser: userIsCogsworthBetaUser,
  IsCogsworthOpsUser: userIsCogsworthOpsUser,
  IsFlexSchedulingUser: userIsFlexSchedulingUser,
  IsResourceLibBetaUser: userIsResourceLibBetaUser,
  IsClinician: userIsClinician,
  IsResourceLibCurator: userIsResourceLibCurator,
  IsRequeuer: userIsRequeuer,
  IsSchedulingUIBetaUser: userIsSchedulingUIBetaUser,
  IsMyClientsWriteAllowed: userIsMyClientsWriteAllowedUser,
  IsQualityBetaUser: userIsQualityBetaUser,
  IsQualityBetaSurveyUser: userIsQualityBetaSurveyUser,
  IsGroupTherapyUser: userIsGroupTherapyUser,
  IsGroupTherapyV2User: userIsGroupTherapyV2User,
  IsCareCoordinator: userIsCareCoordinator,
  IsCareEpisodesUser: userIsCareEpisodesUser,
  IsAvailabilityToolEndDateUser: userIsAvailabilityToolEndDateUser,
  IsDashboardV2User: userIsDashboardV2User,
  IsMHQOLSurveyUser: userIsMHQOLSurveyUser,
  IsNetworkClinician: userIsNetworkClinician,
  IsCareAlertBetaUser: userIsCareAlertBetaUser,
  isHolisticAlertsBetaUser: userIsHolisticBetaUser,
  IsTrainingHoursTimeOffUser: userCanTrainingHoursTimeOff,
  isConsultConsents: userConsultConsents,
};

export type UserPermission = keyof typeof Permissions;

/**
 * Returns true if user has at least one of the permissions provided.
 * @param user CurrentUser as returned from api route /ehr/get_current_user
 * @param permissions Array of UserPermissions as enum in Permissions
 */
export const UserHasAnyPermissions = (
  user: CurrentUser | null,
  permissions: UserPermission[],
) => user && permissions.some((p) => Permissions[p](user));

/**
 * Returns true if user has ALL of the permissions provided.
 * @param user {CurrentUser}
 * @param permissions {UserPermission[]}
 */
export const UserHasAllPermissions = (
  user: CurrentUser | null,
  permissions: UserPermission[],
) => user && permissions.every((p) => Permissions[p](user));

/**
 * Conditionally renders children depending on state.auth.user and given Permissions array.
 *
 * @param props.requireAll {boolean} If true, ALL provided permissions must pass to render children.
 */
export const IfPermitted: React.FC<{
  permissions: UserPermission[];
  requireAll: boolean;
  children?: any;
}> = (props) => {
  const { permissions, requireAll, children } = props;

  const { currentUser } = useShallowEqualSelector((state) => ({
    currentUser: state.auth.currentUser,
  }));

  if (!currentUser || !children) {
    return null;
  }

  if (userIsSuperUser(currentUser)) {
    return children;
  }

  if (requireAll) {
    if (UserHasAllPermissions(currentUser, permissions)) {
      return children;
    }
  } else {
    if (UserHasAnyPermissions(currentUser, permissions)) {
      return children;
    }
  }

  return null;
};

export const useUserHasAnyPermissions = (permissions: UserPermission[]) => {
  const cuser = useShallowEqualSelector((state) => state.auth.currentUser);

  return useMemo(
    () => UserHasAnyPermissions(cuser, permissions),
    [cuser, permissions],
  );
};

export const userCanAccessHealthie = (user: CurrentUser, config?: Config) => {
  return (
    (user && user.groups.includes("Healthie - Beta User")) ||
    (config && config.healthie_ui_enabled)
  );
};

export const userCanAccessCogsworth = (user: CurrentUser, config?: Config) => {
  return user && user.groups.includes("Cogsworth Beta");
};

export const userCanAccessIncompleteNotes = (
  user: CurrentUser,
  config?: Config,
) => {
  return user && user.groups.includes("Healthie - Beta User");
};
