import { Spin } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { $antdBorder, $greyBorder } from "../../assets/colors";
import { slotToolActions } from "../../state/models/slot-tool";
import * as slotToolOperations from "../../state/models/slot-tool/operations";
import { useShallowEqualSelector } from "../_helpers/redux";
import { DateRange } from "../panel-management/types";
import { GridHeader, GridRowHeader } from "./_grid";
import { Cell } from "./Cell";
import { DayOfWeek, daysArray, EventBuckets, timesArray } from "./types";
import { Time } from "../../api/types";
import { UserHasAnyPermissions } from "../_helpers/permissions";
import { useSearchParams } from "react-router-dom";
import { TIMEZONE } from "../my-clients/utils";
import { CurrentUserContext } from "../app.utils";
import { useGetCliniciansTimezone } from "../api/use-get-clinicians/use-get-clinicians";
import { useGetClinicianAuthGroups } from "../api/use-get-clinician-auth-groups/use-get-clinician-auth-groups";
import { getRoofDogBaseUrl } from "../_helpers";
import {
  Timezone,
  TimezoneProvider,
  useTimezoneProvider,
} from "../_shared/TimezoneContext";

const X_HEADER_HEIGHT = "72px";
const Y_HEADER_WIDTH = "80px";
// rows will expand vertically to accomodate more events
const ROW_HEIGHT = "48px";

/**
 *
 * CSS GRID INDICES
 *    8am - 1
 *    9am - 2 ...
 *
 *    Monday - n = 0
 *      Even - 1 (2n + 1)
 *      Odd - 2  (2n + 2)
 *    Tuesday - n = 1
 *      Even - 3
 *      Odd - 4
 */

const SlotTool = () => {
  const cuser = React.useContext(CurrentUserContext);
  const { clinicianId, dateRange, eventBuckets, isLoading } =
    useShallowEqualSelector((state) => ({
      dateRange: state.slottool.dateRange,
      clinicianId: state.slottool.clinicianId,
      eventBuckets: state.slottool.eventBuckets,
      isLoading: state.slottool.isLoading,
    }));

  const dispatch = useDispatch();
  const timezone = useTimezoneProvider();

  const { groupData, isLoadingGroupData } =
    useGetClinicianAuthGroups(clinicianId);
  React.useEffect(() => {
    const isInCogsworthAlpha =
      groupData?.ehr_clinician[0].auth_user?.auth_user_groups
        .map((group) => group.auth_group.name)
        .includes("Cogsworth Alpha");

    if (!isLoadingGroupData && isInCogsworthAlpha) {
      const baseUrl = getRoofDogBaseUrl();
      window.location.replace(
        `${baseUrl}/availability?clinicianId=${clinicianId}`,
      );
    }
  }, [groupData, isLoadingGroupData]);

  const {
    getAndBucketEvents,
    setDateRange,
    setClinicianId,
    getCliniciansManualCapacity,
    getProcedureLimits,
  } = React.useMemo(
    () => ({
      getAndBucketEvents: (clinicianId: number, dateRange: DateRange) => {
        dispatch(
          slotToolOperations.getAndBucketEvents(
            clinicianId,
            dateRange,
            timezone,
          ),
        );
      },
      setDateRange: (dateRange: DateRange) => {
        dispatch(slotToolActions.setDateRange(dateRange));
      },
      setClinicianId: (clinicianId: number) => {
        dispatch(slotToolActions.setClinicianId(clinicianId));
      },
      getCliniciansManualCapacity: () => {
        dispatch(slotToolOperations.getCliniciansManualCapacity());
      },
      getProcedureLimits: () => {
        dispatch(slotToolOperations.getProcedureLimits());
      },
    }),
    [dispatch, timezone],
  );

  if (!dateRange) {
    setDateRange({
      start: moment().add(1, "week").startOf("week"),
      end: moment().add(8, "weeks").endOf("week"),
    });
  }

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (!cuser) {
      // We need cuser to determine if the user has permissions to use the query param.
      // Without this, if a CM is trying to view one of their clinicians, we start fetching
      // the data for the CM which is not what we want.
      return;
    }

    const queryParamId = searchParams.get("clinicianId");
    if (clinicianId) {
      const canUseQueryParam = UserHasAnyPermissions(cuser, [
        "IsClinicalLeader",
        "IsSuperUser",
      ]);
      if (
        canUseQueryParam &&
        queryParamId &&
        queryParamId !== String(clinicianId)
      ) {
        setClinicianId(parseInt(queryParamId));
      } else {
        getAndBucketEvents(clinicianId, dateRange);
        getCliniciansManualCapacity();
        getProcedureLimits();
      }
    } else {
      if (
        queryParamId &&
        UserHasAnyPermissions(cuser, ["IsClinicalLeader", "IsSuperUser"])
      ) {
        setClinicianId(parseInt(queryParamId));
      } else if (cuser?.clinician) {
        setClinicianId(cuser.clinician.id);
      }
    }
  }, [
    cuser,
    clinicianId,
    getAndBucketEvents,
    dateRange,
    setClinicianId,
    getCliniciansManualCapacity,
    getProcedureLimits,
    timezone,
  ]);

  // set up ui components
  const headers = React.useMemo(
    () => generateHeaders(eventBuckets),
    [eventBuckets],
  );
  const lhsHeaders = React.useMemo(() => generateLHSHeaders(), []);
  const tableCells = React.useMemo(
    () => generateCells(eventBuckets),
    [eventBuckets],
  );

  const numColumns = eventBucketsHasDay(eventBuckets, "Saturday") ? 12 : 10;

  return (
    <SlotToolContainer>
      <Spin size="large" spinning={isLoading}>
        <SlotToolGrid numColumns={numColumns}>
          <div
            style={{
              gridRow: 0,
              gridColumn: 0,
              backgroundColor: "#fff",
              height: "100%",
              width: "100%",
              position: "sticky",
              top: 0,
              borderRight: `2px solid ${$greyBorder}`,
              borderBottom: `1px solid ${$greyBorder}`,
            }}
          />
          {headers}
          {lhsHeaders}
          {tableCells}
        </SlotToolGrid>
      </Spin>
    </SlotToolContainer>
  );
};

function eventBucketsHasDay(eventBuckets: EventBuckets, day: DayOfWeek) {
  if (Object.values(eventBuckets[day]).some((array) => array.length > 0)) {
    return true;
  }
  return false;
}

// css grid indices
const timesGridIndices = (() => {
  const indices = {};
  timesArray.map((time, index) => (indices[time] = index + 1));
  return indices;
})();

// odd = given index + 1
const daysGridIndices = (() => {
  const indices = {};
  daysArray.map((day, index) => (indices[day] = index * 2 + 1));
  return indices;
})();

export function generateHeaders(eventBuckets: EventBuckets) {
  return Object.keys(daysGridIndices).map((dayOfWeek, index) => {
    if (
      dayOfWeek === "Saturday" &&
      !eventBucketsHasDay(eventBuckets, "Saturday")
    ) {
      return undefined;
    }
    return (
      <GridHeader dayOfWeek={dayOfWeek} index={index} key={`header_${index}`} />
    );
  });
}

export function generateLHSHeaders() {
  return Object.keys(timesGridIndices).map((time, index) => (
    <GridRowHeader
      time={moment(time, "HH:mm:ss").format("ha")}
      index={index + 2}
      key={`rowHeader_${index}`}
    />
  ));
}

function generateCells(eventBuckets: EventBuckets) {
  const cells: JSX.Element[] = [];

  Object.entries(eventBuckets).forEach(([day, hours], xIndex) => {
    Object.entries(hours).forEach(([hour, events], yIndex) => {
      cells.push(
        <Cell
          day={day as DayOfWeek}
          hour={hour as Time}
          x={xIndex}
          y={yIndex}
          events={events}
          key={`cell_${xIndex}_${yIndex}`}
        />,
      );
    });
  });

  return cells;
}

const SlotToolContainer = styled.div`
  min-height: 80%;
  overflow: scroll;
  margin: 8px;
  border-radius: 4px;

  border: 1px solid ${$antdBorder};
  background-color: ${$greyBorder};

  flex: 1;
`;

export const SlotToolGrid = styled.div<{ numColumns?: number }>`
  display: grid;
  grid-template-columns: ${Y_HEADER_WIDTH} repeat(
      ${(props) => props.numColumns || 10},
      1fr
    );
  grid-template-rows: ${X_HEADER_HEIGHT} repeat(14, minmax(${ROW_HEIGHT}, 1fr));
  height: 100%;
  gap: 1px;

  > * {
    background-color: white;
  }
`;

export default SlotTool;
