import * as React from "react";
import { Alert, Row } from "antd";
import moment from "moment";
import { ClinicianWithSchedule } from "../../api/types";
import { Title } from "../_layout/display";
import { Spacer, Text } from "../design-system";
import { HoldStatusMap } from "../../state/models/clinicians";
import { holdReasons } from "../_helpers";
import { autoSchedulePauseReasons } from "../_helpers";
import { ClinicianSchedulePreference } from "./api/use-clinician-schedule-preference/use-get-clinician-schedule-preference";
import { DateTime } from "luxon";
import { editTargetReasons } from "./EditScheduledSessionsTargetModal";
import styled from "styled-components";

type Props = {
  clinician: ClinicianWithSchedule;
  holdStatus: HoldStatusMap;
  clinicianSchedulePreference: ClinicianSchedulePreference | undefined;
  userSeesCogsworthVersion: boolean | null;
};

export const ListItemBanner = ({
  holdStatus,
  clinician,
  clinicianSchedulePreference,
  userSeesCogsworthVersion,
}: Props) => {
  const showHoldBanner = holdStatus[clinician.id];
  const therapyTargetChanged =
    clinicianSchedulePreference &&
    clinicianSchedulePreference.target_scheduled_sessions !==
      clinicianSchedulePreference.default_target;

  const consultTargetChanged =
    clinicianSchedulePreference &&
    clinicianSchedulePreference.target_scheduled_consults !==
      clinicianSchedulePreference.default_consult_target;

  const showAutoscheduleModifiedBanner =
    userSeesCogsworthVersion &&
    clinicianSchedulePreference &&
    (therapyTargetChanged || consultTargetChanged);

  if (!showHoldBanner && !showAutoscheduleModifiedBanner) {
    return null;
  }

  let editReason = "";
  if (clinicianSchedulePreference) {
    editReason =
      editTargetReasons.find(
        (r) => r.value === clinicianSchedulePreference.change_reason,
      )?.label ?? "";
  }

  return (
    <>
      {showHoldBanner && (
        <Alert
          banner={true}
          showIcon={false}
          message={
            userSeesCogsworthVersion ? (
              <PaddedContainer>
                <Text fontWeight={500}>Autoschedule paused</Text>
                <Spacer4 />
                <Text fontSize={12} fontWeight={400} color="$neutral11">
                  Reach out to cliniciansupport@twochairs.com to lift pause on
                  Client Scheduler
                </Text>
                <Spacer y={16} />
                <Row style={{ flex: 1 }}>
                  <Text fontSize={14} fontWeight={400}>
                    {`Pause date: ${DateTime.fromISO(holdStatus[clinician.id].start_at).toFormat("L/d")}`}
                  </Text>
                </Row>
                <Spacer y={16} />
                <Row style={{ flex: 1 }}>
                  <Text fontSize={14} fontWeight={400}>
                    Pause reason:{" "}
                    {
                      (
                        holdReasons
                          .concat(autoSchedulePauseReasons)
                          .find(
                            (reason) =>
                              reason.key === holdStatus[clinician.id].reason,
                          ) || { value: "Other" }
                      ).value
                    }
                  </Text>
                </Row>
              </PaddedContainer>
            ) : (
              <>
                <Title>Hold Information</Title>
                <Row style={{ flex: 1 }}>
                  <Title size="xs">Date Range:</Title>
                  <div style={{ margin: "0 10px" }}>
                    {moment(holdStatus[clinician.id].start_at).format("l LT")}
                  </div>
                  {"-"}
                  <div style={{ margin: "0 10px" }}>
                    {moment(holdStatus[clinician.id].end_at).format("l LT")}
                  </div>
                </Row>
                <Row style={{ flex: 1 }}>
                  <Title size="xs">Reason:</Title>
                  <div style={{ marginLeft: "10px" }}>
                    {
                      (
                        holdReasons.find(
                          (reason) =>
                            reason.key === holdStatus[clinician.id].reason,
                        ) || { value: "Other" }
                      ).value
                    }
                  </div>
                </Row>
                {holdStatus[clinician.id].note && (
                  <>
                    <Title size="xs" margin="0px">
                      Note:
                    </Title>
                    <div>{holdStatus[clinician.id].note}</div>
                  </>
                )}
              </>
            )
          }
        />
      )}
      {!showHoldBanner && showAutoscheduleModifiedBanner && (
        <Alert
          banner={true}
          showIcon={false}
          message={
            <PaddedContainer>
              <Text
                fontWeight={500}
              >{`${therapyTargetChanged ? "Autoschedule" : "Consult target"} modified`}</Text>
              <Spacer y={16} />
              <Row style={{ flex: 1 }}>
                <Text fontSize={14} fontWeight={400}>
                  {`Modified target: ${
                    therapyTargetChanged
                      ? clinicianSchedulePreference.target_scheduled_sessions
                      : clinicianSchedulePreference.target_scheduled_consults
                  } (default: ${
                    therapyTargetChanged
                      ? clinicianSchedulePreference.default_target
                      : clinicianSchedulePreference.default_consult_target
                  })`}
                </Text>
              </Row>
              <Spacer y={8} />
              <Row style={{ flex: 1 }}>
                <Text fontSize={14} fontWeight={400}>
                  {`Edit date: ${DateTime.fromISO(clinicianSchedulePreference.modified_at).toFormat("L/d")}`}
                </Text>
              </Row>
              {editReason && (
                <>
                  <Spacer y={8} />
                  <Row style={{ flex: 1 }}>
                    <Text fontSize={14} fontWeight={400}>
                      {`Edit reason: ${editReason}`}
                    </Text>
                  </Row>
                </>
              )}
              {clinicianSchedulePreference.change_reason_other && (
                <>
                  <Spacer y={8} />
                  <Row style={{ flex: 1 }}>
                    <Text fontSize={14} fontWeight={400}>
                      {`Other reason description: ${clinicianSchedulePreference.change_reason_other}`}
                    </Text>
                  </Row>
                </>
              )}
              {clinicianSchedulePreference.changed_by_user && (
                <>
                  <Spacer y={8} />
                  <Row style={{ flex: 1 }}>
                    <Text fontSize={14} fontWeight={400}>
                      {`Last modified by: ${clinicianSchedulePreference.changed_by_user}`}
                    </Text>
                  </Row>
                </>
              )}
            </PaddedContainer>
          }
        />
      )}
    </>
  );
};

const Spacer4 = styled.div`
  margin-bottom: 4px;
`;

const PaddedContainer = styled.div`
  padding: 11px 15px;
`;
