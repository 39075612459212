import * as matchOperations from "./operations";
import {
  APIClinicianTag,
  APIConflictOfInterest,
  AQMRestrictions,
  AQMScoreBreakdown,
  AQMScores,
  AQMTuningParams,
  BasicMatch,
  BasicUser,
  CCVRData,
  ClinicianMatchTag,
  ClinicPreference,
  ConsultExtended,
  ConsultNPSForm,
  ConsultNPSFormWithScores,
  Exclusions,
  ExtendedMatch,
  Fit,
  FitSuggestion,
  IdMap,
  MatchPriority,
  MatchSlotPreference,
  MatchSuggestion,
  MatchTag,
  ShoppingCartItem,
  Tag,
} from "../../../api/types";
import {
  actionFactory,
  ActionUnion,
  payloadAction,
  simpleAction,
} from "reductser";
import produce from "immer";
import { assertUnreachable } from "../types";
import { uniqBy, map } from "lodash-es";
import { ClinicianSlotData } from "../../../app/consults-and-matching/ClinicianSlotTool/types";
import {
  getFeedbackAvgScoreFromForm,
  getNpsScoreFromForm,
  sortQueuedMatches,
  toMap,
} from "../../../app/_helpers";

interface LoadingMap {
  [id: number]: boolean;
}
export type ClinicianSubset = "all" | "cuser" | "my_team" | "clinician";
export interface MyConsultsFilter {
  subset: ClinicianSubset;
  clinicianId: number | null;
  userId: number | null;
}

const makeClinicianCartMap = (matchesInCart: {
  [matchId: number]: ShoppingCartItem;
}): { [clinicianId: number]: string[] } => {
  const newClinicianCartMap = {};
  Object.values(matchesInCart).forEach((cartItem) => {
    let clinicianId = cartItem.fit.clinician;
    if (!newClinicianCartMap[clinicianId]) {
      newClinicianCartMap[clinicianId] = [];
    }
    newClinicianCartMap[clinicianId].push(
      cartItem.match.couple?.initials ?? cartItem.match.client.initials,
    );
  });
  return newClinicianCartMap;
};

const actionMap = {
  addMatchesToCart: payloadAction<ShoppingCartItem[]>(),
  createClinicianTag: payloadAction<APIClinicianTag>(),
  deleteClinicianTag: payloadAction<{ clinicianId: number; tagId: number }>(),
  emptyShoppingCart: simpleAction(),
  fetchFitSuggestions: simpleAction(),
  fetchingExtendedMatch: payloadAction<LoadingMap>(),
  fetchingMyMatches: payloadAction<boolean>(),
  fetchMatchSuggestions: simpleAction(),
  fetchQueuedMatches: simpleAction(),
  finishMatchFlowLoading: simpleAction(),
  loadAssignableUsers: payloadAction<BasicUser[]>(),
  loadInclusiveCategories: payloadAction<string[]>(),
  loadMatchSuggestions: payloadAction<{
    maxScore: number;
    suggestions: FitSuggestion[];
  }>(),
  loadQueuedMatches: payloadAction<BasicMatch[]>(),
  loadTags: payloadAction<Tag[]>(),
  patchClinicianTag: payloadAction<{
    clinicianId: number;
    prefer_not_to_treat: boolean;
    tagId: number;
    weight: -1 | 1 | 2 | 4;
  }>(),
  removeMatchFromCart: payloadAction<number>(),
  saveClinicianTags: payloadAction<APIClinicianTag[]>(),
  saveConsultNPSForms: payloadAction<ConsultNPSForm[]>(),
  saveExtendedMatch: payloadAction<ExtendedMatch>(),
  saveMyMatches: payloadAction<BasicMatch[]>(),
  setAqmExclusions: payloadAction<Exclusions>(),
  setAqmScorecard: payloadAction<object>(),
  setAqmScorecardModalIsOpen: payloadAction<boolean>(),
  setAqmScores: payloadAction<AQMScores>(),
  setAqmTunerIsOpen: payloadAction<boolean>(),
  setAqmTuningParams: payloadAction<AQMTuningParams>(),
  setCCVRData: payloadAction<CCVRData>(),
  setClinicianSlotInfo: payloadAction<{
    clinicianId: number;
    clinicianSlotInfo: ClinicianSlotData;
  }>(),
  setConsultTags: payloadAction<IdMap<MatchTag[]>>(),
  setEmailModalMatch: payloadAction<BasicMatch | undefined>(),
  setLoadingAqmScorecard: payloadAction<boolean>(),
  setLoadingClinicianTags: payloadAction<boolean>(),
  setLoadingMatchSuggestions: payloadAction<boolean>(),
  setMatchAdminToolIsOpen: payloadAction<boolean>(),
  setMatchClinicPreferences: payloadAction<IdMap<ClinicPreference[]>>(),
  setMatchConflictsOfInterest: payloadAction<IdMap<APIConflictOfInterest[]>>(),
  setMatchFits: payloadAction<IdMap<Fit[]>>(),
  setMatchFlowLoading: simpleAction(),
  setMatchPriority: payloadAction<{
    matchId: number;
    priority: MatchPriority;
  }>(),
  setMatchReturningToSameClinician: payloadAction<{
    matchId: number;
    returningToSameClinician: boolean;
  }>(),
  setMatchSlotPreferences: payloadAction<{
    [id: number]: MatchSlotPreference[];
  }>(),
  setMatchSuggestions: payloadAction<MatchSuggestion[]>(),
  setQueueConditionID: payloadAction<string | null>(),
  setQueueConditionPaths: payloadAction<object>(),
  setResultsId: payloadAction<number>(),
  setMatchTags: payloadAction<IdMap<MatchTag[]>>(),
  setMyConsultsFilter: payloadAction<MyConsultsFilter>(),
  setShoppingCartIsOpen: payloadAction<boolean>(),
  setShowAqmSuggestions: payloadAction<boolean>(),
  setShowHighAcuityAqmSuggestions: payloadAction<boolean>(),
  toggleMatchIsFlagged: payloadAction<number>(),
  updateMatchAssignees: payloadAction<{
    matchIds: number[];
    assigneeId: number;
  }>(),
  updateMatchInCart: payloadAction<BasicMatch>(),
  updateMatchInMyMatches: payloadAction<BasicMatch>(),

};

export const matchActions = actionFactory(actionMap, "MATCHES");

export type MatchActions = ActionUnion<typeof matchActions>;

export interface MatchState {
  aqmExclusions: Exclusions;
  aqmRestrictions: AQMRestrictions;
  aqmScorecard: object;
  aqmScorecardModalIsOpen: boolean;
  aqmScores: IdMap<IdMap<AQMScoreBreakdown>>;
  aqmTunerIsOpen: boolean;
  aqmTuningParams: AQMTuningParams;
  assignableUsers: BasicUser[];
  ccvrData: CCVRData;
  clinicianCartMap: { [clinicianId: number]: string[] };
  clinicianSlotInfoMap: { [clinicianId: number]: ClinicianSlotData };
  clinicianTags: APIClinicianTag[];
  consultNPSFormMap: { [match_id: number]: ConsultNPSFormWithScores };
  consultTagsMap: IdMap<MatchTag[]>;
  emailModalMatch?: BasicMatch;
  extendedMatchLoadingMap: LoadingMap;
  extendedMatchMap: { [id: number]: ExtendedMatch };
  fetchingMyMatches: boolean;
  flaggedMatches: { [id: number]: boolean };
  inclusiveCategories: string[];
  lastUpdatedQueuedMatchesAt?: number;
  loadingAqmScorecard: boolean;
  loadingClinicianTags: boolean;
  loadingMatchFlow: boolean;
  loadingMatchFlowFits: boolean;
  loadingMatchSuggestions: boolean;
  loadingQueuedMatches: boolean;
  matchAdminToolIsOpen: boolean;
  matchClinicPreferencesMap: IdMap<ClinicPreference[]>;
  matchConflictsOfInterestMap: IdMap<APIConflictOfInterest[]>;
  matchesInCart: { [matchId: number]: ShoppingCartItem };
  matchFitsMap: { [id: number]: Fit[] };
  matchFlowFits: FitSuggestion[];
  matchFlowMaxScore: number;
  matchSlotPreferenceMap: { [id: number]: MatchSlotPreference[] };
  matchSuggestions: IdMap<MatchSuggestion>;
  matchTagsMap: IdMap<MatchTag[]>;
  myConsultsFilter: MyConsultsFilter;
  myMatchesMap: IdMap<BasicMatch>;
  queue_condition_id: string | null;
  queuedMatches: BasicMatch[];
  showAqmSuggestions: boolean;
  showHighAcuityAqmSuggestions: boolean;
  shoppingCartIsOpen: boolean;
  tagMap: { [id: number]: Tag };
  tags: Tag[];
  queueConditionPaths: object;
  resultsId: number;
}

function getInitialState(): MatchState {
  return {
    aqmExclusions: { match_ids: [], fit_ids: [] },
    aqmRestrictions: {},
    aqmScorecard: {},
    aqmScorecardModalIsOpen: false,
    aqmScores: {},
    aqmTunerIsOpen: false,
    aqmTuningParams: {
      acuity_factor: 0,
      baseline_factor: 0,
      biweekly_cadence_match_factor: 1,
      biweekly_only_to_weekly_slot_factor: 1,
      biweekly_preference_to_weekly_slot_factor: 1,
      biweekly_preference_to_weekly_threshold_factor: 0,
      cadence_pref_override_threshold_factor: 0,
      client_matchability_factor: 0,
      clinician_cap_factor: 0,
      clinician_tenure_factor: 0,
      either_to_in_person_factor: 1,
      either_to_tele_factor: 1,
      fit_score_factor: 0,
      in_person_to_in_person_factor: 1,
      kp_mismatch_factor: 1,
      kp_referral_factor: 0,
      location_preference_factor: 0,
      long_term_clinician_matchability_factor: 0,
      maybe_slot_factor: 0,
      priority_factor: 0,
      queue_time_factor: 0,
      queue_time_squared_factor: 0,
      rematch_factor: 0,
      same_clinician_factor: 0,
      session_clinician_matchability_factor: 0,
      sooner_slot_factor: 5,
      starred_benefits_factor: 0,
      starred_needs_factor: 0,
      start_tele_to_in_person_factor: 1,
      start_tele_to_tele_factor: 1,
      tele_to_in_person_factor: 1,
      tele_to_tele_factor: 1,
      weekly_cadence_match_factor: 1,
      weekly_preference_to_biweekly_slot_factor: 1,
      weekly_preference_to_biweekly_threshold_factor: 0,
      wheel_clinician_factor: 1,
      boolean_fit_aetna_to_wheel_factor: true,
      boolean_fit_dtc_to_wheel_factor: true,
      boolean_enforce_inperson_slots: false,
      network_target_down_adjustment_california_factor: 1,
      network_target_down_adjustment_florida_factor: 1,
      network_target_down_adjustment_washington_factor: 1,
    },
    assignableUsers: [],
    ccvrData: {},
    clinicianCartMap: {},
    clinicianSlotInfoMap: {},
    clinicianTags: [],
    consultNPSFormMap: {},
    consultTagsMap: {},
    emailModalMatch: undefined,
    extendedMatchLoadingMap: {},
    extendedMatchMap: {},
    fetchingMyMatches: false,
    flaggedMatches: {},
    inclusiveCategories: [],
    lastUpdatedQueuedMatchesAt: undefined,
    loadingAqmScorecard: false,
    loadingClinicianTags: false,
    loadingMatchFlow: false,
    loadingMatchFlowFits: false,
    loadingMatchSuggestions: false,
    loadingQueuedMatches: false,
    matchAdminToolIsOpen: false,
    matchClinicPreferencesMap: {},
    matchConflictsOfInterestMap: {},
    matchesInCart: {},
    matchFitsMap: {},
    matchFlowFits: [],
    matchFlowMaxScore: 0,
    matchSlotPreferenceMap: {},
    matchSuggestions: {},
    matchTagsMap: {},
    myConsultsFilter: {
      subset: "cuser",
      clinicianId: null,
      userId: null,
    },
    myMatchesMap: {},
    queue_condition_id: null,
    queuedMatches: [],
    shoppingCartIsOpen: false,
    showAqmSuggestions: false,
    showHighAcuityAqmSuggestions: false,
    tagMap: {},
    tags: [],
    queueConditionPaths: {},
    resultsId: 0,
  };
}

const reducer = (state = getInitialState(), action: MatchActions) =>
  produce(state, (draft) => {
    if (action.reducer === "MATCHES") {
      switch (action.type) {
        case "addMatchesToCart":
          action.payload.forEach((item) => {
            if (item.fit && item.match) {
              if (draft.matchesInCart.hasOwnProperty(item.match.id)) {
                item.match.match_note = "";
              }
              draft.matchesInCart[item.match.id] = item;
            }
          });
          draft.clinicianCartMap = makeClinicianCartMap(draft.matchesInCart);
          return;
        case "createClinicianTag":
          draft.clinicianTags = uniqBy(
            draft.clinicianTags.concat([action.payload]),
            "id",
          );
          return;
        case "deleteClinicianTag":
          draft.clinicianTags = draft.clinicianTags.filter(
            (ct) =>
              !(
                ct.clinician_id === action.payload.clinicianId &&
                ct.tag_id === action.payload.tagId
              ),
          );
          return;
        case "emptyShoppingCart":
          draft.matchesInCart = {};
          draft.clinicianCartMap = {};
          return;
        case "fetchingExtendedMatch":
          draft.extendedMatchLoadingMap = {
            ...draft.extendedMatchLoadingMap,
            ...action.payload,
          };
          return;
        case "fetchFitSuggestions":
          draft.loadingMatchFlowFits = true;
          return;
        case "fetchingMyMatches":
          draft.fetchingMyMatches = action.payload;
          return;
        case "fetchMatchSuggestions":
          draft.loadingMatchSuggestions = true;
          return;
        case "fetchQueuedMatches":
          draft.loadingQueuedMatches = true;
          return;
        case "finishMatchFlowLoading":
          draft.loadingMatchFlow = false;
          return;
        case "loadAssignableUsers":
          draft.assignableUsers = action.payload;
          return;
        case "loadInclusiveCategories":
          draft.inclusiveCategories = action.payload;
          return;
        case "loadMatchSuggestions":
          draft.matchFlowMaxScore = action.payload.maxScore;
          draft.matchFlowFits = action.payload.suggestions;
          draft.loadingMatchFlowFits = false;
          return;
        case "loadQueuedMatches":
          draft.lastUpdatedQueuedMatchesAt = Date.now();
          draft.queuedMatches = sortQueuedMatches(action.payload);
          draft.loadingQueuedMatches = false;
          return;
        case "loadTags":
          draft.tags = action.payload;
          draft.tagMap = toMap(action.payload);
          return;
        case "patchClinicianTag":
          draft.clinicianTags = draft.clinicianTags.map((ct) =>
            ct.clinician_id === action.payload.clinicianId &&
            ct.tag_id === action.payload.tagId
              ? {
                  ...ct,
                  weight: action.payload.weight,
                  prefer_not_to_treat: action.payload.prefer_not_to_treat,
                }
              : ct,
          );
          return;
        case "removeMatchFromCart":
          delete draft.matchesInCart[action.payload];
          draft.clinicianCartMap = makeClinicianCartMap(draft.matchesInCart);
          return;
        case "saveClinicianTags":
          draft.clinicianTags = uniqBy(
            draft.clinicianTags.concat(action.payload),
            "id",
          );
          return;
        case "saveConsultNPSForms":
          action.payload.forEach((form) => {
            draft.consultNPSFormMap[form.match_id] = {
              form,
              nps: getNpsScoreFromForm(form),
              feedbackAvg: getFeedbackAvgScoreFromForm(form),
            };
          });
          return;
        case "saveExtendedMatch":
          draft.extendedMatchMap[action.payload.id] = action.payload;
          return;
        case "saveMyMatches":
          draft.myMatchesMap = { ...draft.myMatchesMap };
          action.payload.forEach((match) => {
            draft.myMatchesMap[match.id] = match;
          });
          return;
        case "setAqmExclusions":
          draft.aqmExclusions = action.payload;
          return;
        case "setAqmScorecard":
          draft.aqmScorecard = action.payload;
          return;
        case "setAqmScorecardModalIsOpen":
          draft.aqmScorecardModalIsOpen = action.payload;
          return;
        case "setAqmScores":
          draft.aqmScores = action.payload;
          return;
        case "setAqmTunerIsOpen":
          draft.aqmTunerIsOpen = action.payload;
          return;
        case "setAqmTuningParams":
          draft.aqmTuningParams = action.payload;
          return;
        case "setCCVRData":
          draft.ccvrData = action.payload;
          return;
        case "setClinicianSlotInfo":
          draft.clinicianSlotInfoMap[action.payload.clinicianId] =
            action.payload.clinicianSlotInfo;
          return;
        case "setConsultTags":
          draft.consultTagsMap = {
            ...draft.consultTagsMap,
            ...action.payload,
          };
          return;
        case "setEmailModalMatch":
          draft.emailModalMatch = action.payload;
          return;
        case "setLoadingAqmScorecard":
          draft.loadingAqmScorecard = action.payload;
          return;
        case "setLoadingClinicianTags":
          draft.loadingClinicianTags = action.payload;
          return;
        case "setLoadingMatchSuggestions":
          draft.loadingMatchSuggestions = action.payload;
          return;
        case "setMatchAdminToolIsOpen":
          draft.matchAdminToolIsOpen = action.payload;
          return;
        case "setMatchClinicPreferences":
          draft.matchClinicPreferencesMap = {
            ...draft.matchClinicPreferencesMap,
            ...action.payload,
          };
          return;
        case "setMatchConflictsOfInterest":
          draft.matchConflictsOfInterestMap = {
            ...draft.matchConflictsOfInterestMap,
            ...action.payload,
          };
          return;
        case "setMatchFits":
          draft.matchFitsMap = {
            ...draft.matchFitsMap,
            ...action.payload,
          };
          return;
        case "setMatchPriority":
          draft.queuedMatches = map(state.queuedMatches, (m) =>
            m.id === action.payload.matchId
              ? {
                  ...m,
                  priority: action.payload.priority,
                }
              : m,
          );
          draft.queuedMatches = sortQueuedMatches(draft.queuedMatches);
          draft.myMatchesMap[action.payload.matchId] = {
            ...draft.myMatchesMap[action.payload.matchId],
            priority: action.payload.priority,
          };
          return;
        case "setMatchReturningToSameClinician":
          draft.queuedMatches = map(state.queuedMatches, (m) =>
            m.id === action.payload.matchId
              ? {
                  ...m,
                  returning_to_same_clinician:
                    action.payload.returningToSameClinician,
                }
              : m,
          );
          draft.queuedMatches = sortQueuedMatches(draft.queuedMatches);
          draft.myMatchesMap[action.payload.matchId] = {
            ...draft.myMatchesMap[action.payload.matchId],
            returning_to_same_clinician:
              action.payload.returningToSameClinician,
          };
          return;
        case "setMatchSlotPreferences":
          draft.matchSlotPreferenceMap = {
            ...draft.matchSlotPreferenceMap,
            ...action.payload,
          };
          return;
        case "setMatchSuggestions":
          draft.matchSuggestions = {};
          action.payload.forEach(
            (suggestion) =>
              (draft.matchSuggestions[suggestion.match_id] = suggestion),
          );
          draft.loadingMatchSuggestions = false;
          return;
        case "setQueueConditionID":
          draft.queue_condition_id = action.payload;
          return;
        case "setMatchTags":
          draft.matchTagsMap = {
            ...draft.matchTagsMap,
            ...action.payload,
          };
          return;
        case "setMyConsultsFilter":
          draft.myConsultsFilter = action.payload;
          return;
        case "setShoppingCartIsOpen":
          draft.shoppingCartIsOpen = action.payload;
          return;
        case "setShowAqmSuggestions":
          draft.showAqmSuggestions = action.payload;
          return;
        case "setShowHighAcuityAqmSuggestions":
          draft.showHighAcuityAqmSuggestions = action.payload;
          return;
        case "setMatchFlowLoading":
          draft.loadingMatchFlow = true;
          return;
        case "toggleMatchIsFlagged":
          draft.flaggedMatches[action.payload] =
            !draft.flaggedMatches[action.payload];
          return;
        case "updateMatchAssignees":
          action.payload.matchIds.forEach((id) => {
            draft.myMatchesMap[id].assignee = action.payload.assigneeId;
          });
          draft.queuedMatches = draft.queuedMatches.map((match) => {
            if (action.payload.matchIds.includes(match.id)) {
              return {
                ...match,
                assignee: action.payload.assigneeId,
              };
            } else {
              return match;
            }
          });
          return;
        case "updateMatchInCart":
          draft.matchesInCart[action.payload.id].match = {
            ...draft.matchesInCart[action.payload.id],
            ...action.payload,
          };
          draft.myMatchesMap = {
            ...draft.myMatchesMap,
            ...{
              [action.payload.id]: action.payload,
            },
          };
          draft.clinicianCartMap = makeClinicianCartMap(draft.matchesInCart);
          return;
        case "updateMatchInMyMatches":
          draft.myMatchesMap = {
            ...draft.myMatchesMap,
            ...{
              [action.payload.id]: action.payload,
            },
          };
          return;
        case "setQueueConditionPaths":
          draft.queueConditionPaths = action.payload;
          return;
        case "setResultsId":
          draft.resultsId = action.payload;
          return;
        default:
          assertUnreachable(action);
      }
    }
  });

export default reducer;
export { matchOperations };
