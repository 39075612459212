import {
  CheckOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Drawer, InputNumber, Tooltip, Input, Checkbox } from "antd";
import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import { useCheckbox, useInput, useInputTextArea } from "./_hooks";
import { matchActions, matchOperations } from "../../state/models/matches";
import { useShallowEqualSelector } from "../_helpers/redux";
import { Row } from "../_layout/Flex";
import { startCase } from "lodash-es";
import { AQMTuningParams, AQMTuningParamsBase } from "../../api/types";

function AqmTuner() {
  const [aqmTunerIsOpen, aqmTuningParams] = useShallowEqualSelector((state) => [
    state.matches.aqmTunerIsOpen,
    state.matches.aqmTuningParams,
  ]);

  const dispatch = useDispatch();

  const closeAqmTuner = () => {
    dispatch(matchOperations.setAqmTuningParams(inputParams));
    dispatch(matchActions.setAqmTunerIsOpen(false));
  };

  const inputs: AQMTuningParamsBase<any> = {
    acuity_factor: useInput(aqmTuningParams.acuity_factor),
    baseline_factor: useInput(aqmTuningParams.baseline_factor),
    biweekly_cadence_match_factor: useInput(
      aqmTuningParams.biweekly_cadence_match_factor,
    ),
    biweekly_only_to_weekly_slot_factor: useInput(
      aqmTuningParams.biweekly_only_to_weekly_slot_factor,
    ),
    biweekly_preference_to_weekly_slot_factor: useInput(
      aqmTuningParams.biweekly_preference_to_weekly_slot_factor,
    ),
    biweekly_preference_to_weekly_threshold_factor: useInput(
      aqmTuningParams.biweekly_preference_to_weekly_threshold_factor,
    ),
    cadence_pref_override_threshold_factor: useInput(
      aqmTuningParams.cadence_pref_override_threshold_factor,
    ),
    client_matchability_factor: useInput(
      aqmTuningParams.client_matchability_factor,
    ),
    clinician_cap_factor: useInput(aqmTuningParams.clinician_cap_factor),
    long_term_clinician_matchability_factor: useInput(
      aqmTuningParams.long_term_clinician_matchability_factor,
    ),
    clinician_tenure_factor: useInput(aqmTuningParams.clinician_tenure_factor),
    either_to_in_person_factor: useInput(
      aqmTuningParams.either_to_in_person_factor,
    ),
    either_to_tele_factor: useInput(aqmTuningParams.either_to_tele_factor),
    fit_score_factor: useInput(aqmTuningParams.fit_score_factor),
    in_person_to_in_person_factor: useInput(
      aqmTuningParams.in_person_to_in_person_factor,
    ),
    kp_mismatch_factor: useInput(aqmTuningParams.kp_mismatch_factor),
    kp_referral_factor: useInput(aqmTuningParams.kp_referral_factor),
    location_preference_factor: useInput(
      aqmTuningParams.location_preference_factor,
    ),
    maybe_slot_factor: useInput(aqmTuningParams.maybe_slot_factor),
    priority_factor: useInput(aqmTuningParams.priority_factor),
    queue_time_factor: useInput(aqmTuningParams.queue_time_factor),
    queue_time_squared_factor: useInput(
      aqmTuningParams.queue_time_squared_factor,
    ),
    rematch_factor: useInput(aqmTuningParams.rematch_factor),
    same_clinician_factor: useInput(aqmTuningParams.same_clinician_factor),
    session_clinician_matchability_factor: useInput(
      aqmTuningParams.session_clinician_matchability_factor,
    ),
    sooner_slot_factor: useInput(aqmTuningParams.sooner_slot_factor),
    starred_benefits_factor: useInput(aqmTuningParams.starred_benefits_factor),
    starred_needs_factor: useInput(aqmTuningParams.starred_needs_factor),
    start_tele_to_in_person_factor: useInput(
      aqmTuningParams.start_tele_to_in_person_factor,
    ),
    start_tele_to_tele_factor: useInput(
      aqmTuningParams.start_tele_to_tele_factor,
    ),
    tele_to_in_person_factor: useInput(
      aqmTuningParams.tele_to_in_person_factor,
    ),
    tele_to_tele_factor: useInput(aqmTuningParams.tele_to_tele_factor),
    weekly_cadence_match_factor: useInput(
      aqmTuningParams.weekly_cadence_match_factor,
    ),
    weekly_preference_to_biweekly_slot_factor: useInput(
      aqmTuningParams.weekly_preference_to_biweekly_slot_factor,
    ),
    weekly_preference_to_biweekly_threshold_factor: useInput(
      aqmTuningParams.weekly_preference_to_biweekly_threshold_factor,
    ),
    wheel_clinician_factor: useInput(aqmTuningParams.wheel_clinician_factor),

    // fit factors are booleans, applied during fit creation and not in the solver
    boolean_fit_aetna_to_wheel_factor: useCheckbox(
      aqmTuningParams.boolean_fit_aetna_to_wheel_factor,
    ),
    boolean_fit_dtc_to_wheel_factor: useCheckbox(
      aqmTuningParams.boolean_fit_dtc_to_wheel_factor,
    ),

    // boolean applied in queue condition
    boolean_enforce_inperson_slots: useCheckbox(
      aqmTuningParams.boolean_enforce_inperson_slots,
    ),

    network_target_down_adjustment_california_factor: useInput(
      aqmTuningParams.network_target_down_adjustment_california_factor,
    ),
    network_target_down_adjustment_florida_factor: useInput(
      aqmTuningParams.network_target_down_adjustment_florida_factor,
    ),
    network_target_down_adjustment_washington_factor: useInput(
      aqmTuningParams.network_target_down_adjustment_washington_factor,
    ),
  };

  const resetChanges = () => {
    inputs.acuity_factor.setValue(aqmTuningParams.acuity_factor);
    inputs.baseline_factor.setValue(aqmTuningParams.baseline_factor);
    inputs.biweekly_cadence_match_factor.setValue(
      aqmTuningParams.biweekly_cadence_match_factor,
    );
    inputs.biweekly_only_to_weekly_slot_factor.setValue(
      aqmTuningParams.biweekly_only_to_weekly_slot_factor,
    );
    inputs.biweekly_preference_to_weekly_slot_factor.setValue(
      aqmTuningParams.biweekly_preference_to_weekly_slot_factor,
    );
    inputs.biweekly_preference_to_weekly_threshold_factor.setValue(
      aqmTuningParams.biweekly_preference_to_weekly_threshold_factor,
    );
    inputs.cadence_pref_override_threshold_factor.setValue(
      aqmTuningParams.cadence_pref_override_threshold_factor,
    );
    inputs.client_matchability_factor.setValue(
      aqmTuningParams.client_matchability_factor,
    );
    inputs.clinician_cap_factor.setValue(aqmTuningParams.clinician_cap_factor);
    inputs.long_term_clinician_matchability_factor.setValue(
      aqmTuningParams.long_term_clinician_matchability_factor,
    );
    inputs.clinician_tenure_factor.setValue(
      aqmTuningParams.clinician_tenure_factor,
    );
    inputs.either_to_in_person_factor.setValue(
      aqmTuningParams.either_to_in_person_factor,
    );
    inputs.either_to_tele_factor.setValue(
      aqmTuningParams.either_to_tele_factor,
    );
    inputs.fit_score_factor.setValue(aqmTuningParams.fit_score_factor);
    inputs.in_person_to_in_person_factor.setValue(
      aqmTuningParams.in_person_to_in_person_factor,
    );
    inputs.kp_mismatch_factor.setValue(aqmTuningParams.kp_mismatch_factor);
    inputs.kp_referral_factor.setValue(aqmTuningParams.kp_referral_factor);
    inputs.location_preference_factor.setValue(
      aqmTuningParams.location_preference_factor,
    );
    inputs.maybe_slot_factor.setValue(aqmTuningParams.maybe_slot_factor);
    inputs.priority_factor.setValue(aqmTuningParams.priority_factor);
    inputs.queue_time_factor.setValue(aqmTuningParams.queue_time_factor);
    inputs.queue_time_squared_factor.setValue(
      aqmTuningParams.queue_time_squared_factor,
    );
    inputs.rematch_factor.setValue(aqmTuningParams.rematch_factor);
    inputs.same_clinician_factor.setValue(
      aqmTuningParams.same_clinician_factor,
    );
    inputs.session_clinician_matchability_factor.setValue(
      aqmTuningParams.session_clinician_matchability_factor,
    );
    inputs.starred_benefits_factor.setValue(
      aqmTuningParams.starred_benefits_factor,
    );
    inputs.sooner_slot_factor.setValue(aqmTuningParams.sooner_slot_factor);
    inputs.starred_needs_factor.setValue(aqmTuningParams.starred_needs_factor);
    inputs.start_tele_to_in_person_factor.setValue(
      aqmTuningParams.start_tele_to_in_person_factor,
    );
    inputs.start_tele_to_tele_factor.setValue(
      aqmTuningParams.start_tele_to_tele_factor,
    );
    inputs.tele_to_in_person_factor.setValue(
      aqmTuningParams.tele_to_in_person_factor,
    );
    inputs.tele_to_tele_factor.setValue(aqmTuningParams.tele_to_tele_factor);
    inputs.weekly_cadence_match_factor.setValue(
      aqmTuningParams.weekly_cadence_match_factor,
    );
    inputs.weekly_preference_to_biweekly_slot_factor.setValue(
      aqmTuningParams.weekly_preference_to_biweekly_slot_factor,
    );
    inputs.weekly_preference_to_biweekly_threshold_factor.setValue(
      aqmTuningParams.weekly_preference_to_biweekly_threshold_factor,
    );
    inputs.wheel_clinician_factor.setValue(
      aqmTuningParams.wheel_clinician_factor,
    );

    inputs.boolean_fit_aetna_to_wheel_factor.setValue(
      aqmTuningParams.boolean_fit_aetna_to_wheel_factor,
    );
    inputs.boolean_fit_dtc_to_wheel_factor.setValue(
      aqmTuningParams.boolean_fit_dtc_to_wheel_factor,
    );

    inputs.boolean_enforce_inperson_slots.setValue(
      aqmTuningParams.boolean_enforce_inperson_slots,
    );

    inputs.network_target_down_adjustment_california_factor.setValue(
      aqmTuningParams.network_target_down_adjustment_california_factor,
    );
    inputs.network_target_down_adjustment_washington_factor.setValue(
      aqmTuningParams.network_target_down_adjustment_washington_factor,
    );
    inputs.network_target_down_adjustment_florida_factor.setValue(
      aqmTuningParams.network_target_down_adjustment_florida_factor,
    );
  };

  useEffect(resetChanges, [aqmTuningParams]);

  const inputParams: AQMTuningParamsBase<number | boolean> = {
    acuity_factor: inputs.acuity_factor.value,
    baseline_factor: inputs.baseline_factor.value,
    biweekly_cadence_match_factor: inputs.biweekly_cadence_match_factor.value,
    biweekly_only_to_weekly_slot_factor:
      inputs.biweekly_only_to_weekly_slot_factor.value,
    biweekly_preference_to_weekly_slot_factor:
      inputs.biweekly_preference_to_weekly_slot_factor.value,
    biweekly_preference_to_weekly_threshold_factor:
      inputs.biweekly_preference_to_weekly_threshold_factor.value,
    cadence_pref_override_threshold_factor:
      inputs.cadence_pref_override_threshold_factor.value,
    client_matchability_factor: inputs.client_matchability_factor.value,
    clinician_cap_factor: inputs.clinician_cap_factor.value,
    long_term_clinician_matchability_factor:
      inputs.long_term_clinician_matchability_factor.value,
    clinician_tenure_factor: inputs.clinician_tenure_factor.value,
    either_to_in_person_factor: inputs.either_to_in_person_factor.value,
    either_to_tele_factor: inputs.either_to_tele_factor.value,
    fit_score_factor: inputs.fit_score_factor.value,
    in_person_to_in_person_factor: inputs.in_person_to_in_person_factor.value,
    kp_mismatch_factor: inputs.kp_mismatch_factor.value,
    kp_referral_factor: inputs.kp_referral_factor.value,
    location_preference_factor: inputs.location_preference_factor.value,
    maybe_slot_factor: inputs.maybe_slot_factor.value,
    priority_factor: inputs.priority_factor.value,
    queue_time_factor: inputs.queue_time_factor.value,
    queue_time_squared_factor: inputs.queue_time_squared_factor.value,
    rematch_factor: inputs.rematch_factor.value,
    same_clinician_factor: inputs.same_clinician_factor.value,
    session_clinician_matchability_factor:
      inputs.session_clinician_matchability_factor.value,
    sooner_slot_factor: inputs.sooner_slot_factor.value,
    starred_benefits_factor: inputs.starred_benefits_factor.value,

    starred_needs_factor: inputs.starred_needs_factor.value,
    start_tele_to_in_person_factor: inputs.start_tele_to_in_person_factor.value,
    start_tele_to_tele_factor: inputs.start_tele_to_tele_factor.value,
    tele_to_in_person_factor: inputs.tele_to_in_person_factor.value,
    tele_to_tele_factor: inputs.tele_to_tele_factor.value,
    weekly_cadence_match_factor: inputs.weekly_cadence_match_factor.value,
    weekly_preference_to_biweekly_slot_factor:
      inputs.weekly_preference_to_biweekly_slot_factor.value,
    weekly_preference_to_biweekly_threshold_factor:
      inputs.weekly_preference_to_biweekly_threshold_factor.value,
    wheel_clinician_factor: inputs.wheel_clinician_factor.value,

    network_target_down_adjustment_california_factor:
      inputs.network_target_down_adjustment_california_factor.value,
    network_target_down_adjustment_florida_factor:
      inputs.network_target_down_adjustment_florida_factor.value,
    network_target_down_adjustment_washington_factor:
      inputs.network_target_down_adjustment_washington_factor.value,

    boolean_fit_aetna_to_wheel_factor:
      inputs.boolean_fit_aetna_to_wheel_factor.value,
    boolean_fit_dtc_to_wheel_factor:
      inputs.boolean_fit_dtc_to_wheel_factor.value,

    boolean_enforce_inperson_slots: inputs.boolean_enforce_inperson_slots.value,
  };

  const saveChanges = () => {
    dispatch(matchOperations.saveAqmTuningParams(inputParams));
  };

  const reloadParams = useCallback(() => {
    dispatch(matchOperations.setAqmTuningParams());
  }, [dispatch]);

  useEffect(() => {
    if (aqmTunerIsOpen) {
      reloadParams();
    }
  }, [aqmTunerIsOpen, reloadParams]);

  const tooltips: AQMTuningParamsBase<string> = {
    acuity_factor: "Add this number if the match is high acuity.",
    baseline_factor:
      "Add this number to every match. Increase this number to prioritize total number of matches made.",
    biweekly_cadence_match_factor:
      "Multiply the total score by this number if the client prefers biweekly and the slot is biweekly",
    biweekly_only_to_weekly_slot_factor:
      "Multiply the total score by this number if the client only wants biweekly, the slot is weekly, and the ToQ threshold has been passed",
    biweekly_preference_to_weekly_slot_factor:
      "Multiply the total score by this number if the client prefers biweekly and the slot is weekly",
    biweekly_preference_to_weekly_threshold_factor:
      "The match can be made if the client prefers biweekly, the slot is weekly, and the match has been on the queue for this many days",
    cadence_pref_override_threshold_factor:
      "The match can be made if there is a cadence preference mismatch and the clinician has at least this much capacity",
    client_matchability_factor: `Multiply this number by 1 - (the number of clinician slots this client can be matched to
        divided by the highest number of slots any client on the queue can be matched to).`,
    clinician_cap_factor: "Multiply this number by the clinician's capacity.",
    clinician_tenure_factor: `Multiply this number by the number of days the clinician has been working, divided by 90.
    If the clinician has been working more than 90 days, this full value is added`,
    either_to_in_person_factor:
      "Multiply the total score by this number if the client is open to hybrid or teletherapy and the slot is in person",
    either_to_tele_factor:
      "Multiply the total score by this number if the client is open to hybrid or teletherapy and the slot is remote",
    fit_score_factor:
      "Multiply this number by the clinician's clinical fit score, divided by the highest fit score for the match",
    in_person_to_in_person_factor:
      "Multiply the total score by this number if the client prefers hybrid therapy and the slot is in person",
    kp_mismatch_factor:
      "Multiply the total score by this number if the client is not a KP referral and the clinician is KP credentialed",
    kp_referral_factor:
      "Add this number if the client has a Kaiser Permanente referral",
    location_preference_factor:
      "Add this number if the client has a strong preference for the slot's location",
    long_term_clinician_matchability_factor: `Multiply this number by 1 - (the number of all
        clients this clinician is a fit for divided by the number of clients).`,
    maybe_slot_factor: "Add this number if the slot is a maybe slot",
    priority_factor: "Add this number if the match is high priority.",
    queue_time_factor:
      "Multiply this number by the number of days the match has been on the queue.",
    queue_time_squared_factor:
      "Multiply this number by the square of the number of days the match has been on the queue.",
    rematch_factor: "Add this number if the match is a rematch.",
    same_clinician_factor:
      "Add this number if the client is being returning to care with their prior clinician.",
    session_clinician_matchability_factor: `Multiply this number by 1 - (the number of queued
          clients this clincian is a fit for divided by the length of the queue).`,
    sooner_slot_factor: `Multiply this number by ((max(days_to_timeslot) - days_to_timeslot) / max(days_to_timeslot))`,
    starred_benefits_factor:
      "Multiply this number by the fraction of starred benefits the clinician has",
    starred_needs_factor:
      "Multiply this number by the fraction of starred needs the clinician has",
    start_tele_to_in_person_factor:
      "Multiply the total score by this number if the client is open to starting teletherapy and the clinician is WFC",
    start_tele_to_tele_factor:
      "Multiply the total score by this number if the client is open to starting teletherapy and the clinician is WFH",
    tele_to_in_person_factor:
      "Multiply the total score by this number if the client wants teletherapy and the slot is in-person",
    tele_to_tele_factor:
      "Multiply the total score by this number if the client only wants teletherapy and the clinician is WFH",
    weekly_cadence_match_factor:
      "Multiply the total score by this number if the client prefers weekly and the slot is weekly",
    weekly_preference_to_biweekly_slot_factor:
      "Multiply the total score by this number if the client prefers weekly and the slot is biweekly",
    weekly_preference_to_biweekly_threshold_factor:
      "The match can be made if the client prefers weekly, the slot is biweekly, and the match has been on the queue for this many days",
    wheel_clinician_factor:
      "Multiply the total score by this number if the clinician is a Wheel clinician",
    boolean_fit_aetna_to_wheel_factor:
      "Allow Aetna clients to be matched to Wheel clinicians",
    boolean_fit_dtc_to_wheel_factor:
      "Allow DTC clients to be matched to Wheel clinicians",
    boolean_enforce_inperson_slots:
      "Enforces that in-person slots go to hybrid clients",
    network_target_down_adjustment_washington_factor:
      "Value [0, 1], scales the target scheduled sessions for individual Washington Network clinicians.",
    network_target_down_adjustment_california_factor:
      "Value [0, 1], scales the target scheduled sessions for individual California Network clinicians.",
    network_target_down_adjustment_florida_factor:
      "Value [0, 1], scales the target scheduled sessions for individual Florida Network clinicians.",
  };

  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 8,
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  const { value: paramsName, bind: bindParamsName } = useInputTextArea("");

  const saveTestParams = () => {
    dispatch(matchOperations.saveAqmTuningTestParams(paramsName, inputParams));
  };

  return (
    <Drawer
      bodyStyle={{ padding: 10 }}
      closable={false}
      onClose={closeAqmTuner}
      title={
        <Row layout="space-between center">
          <span>AQM Tuner</span>
          <>
            <Button
              onClick={closeAqmTuner}
              type="primary"
              icon={<CheckOutlined />}
            />
          </>
        </Row>
      }
      open={aqmTunerIsOpen}
      width={500}
    >
      <Form {...layout} onSubmit={saveChanges}>
        {Object.keys(aqmTuningParams)
          .sort()
          .map((param) => (
            <Form.Item
              key={param}
              label={
                <span>
                  {startCase(param).slice(0, -7)}{" "}
                  <Tooltip title={tooltips[param]}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              required={true}
              style={{ marginBottom: 0 }}
            >
              {param.startsWith("boolean_") ? (
                <Checkbox
                  onChange={inputs[param]?.bind.onChange}
                  checked={inputs[param]?.value}
                />
              ) : (
                <InputNumber
                  onChange={inputs[param]?.setValue}
                  value={inputs[param]?.value}
                />
              )}
            </Form.Item>
          ))}
        <Form.Item {...tailFormItemLayout}>
          <Button
            icon={<ReloadOutlined />}
            onClick={reloadParams}
            style={{ marginLeft: 26 }}
          >
            Reset
          </Button>
          <Button
            onClick={saveChanges}
            style={{ marginLeft: 40 }}
            type="primary"
          >
            Save Changes
          </Button>
          <Input.TextArea
            placeholder="Add name for test params."
            {...bindParamsName}
          />
          <Button
            onClick={saveTestParams}
            style={{ marginLeft: 40 }}
            type="primary"
          >
            Save Test Params
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default AqmTuner;
