import * as Sentry from "@sentry/react";
import * as React from "react";
import TagManager from "react-gtm-module";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CookiesProvider, useCookies } from "react-cookie";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  CurrentUserContext,
  CurrentConfigContext,
  useCarePlatform,
  withRouteSentryWrapper,
} from "./app.utils";

import Login from "./login";
import { AuthRouteContent } from "./auth/auth-route-content";
import { PrivateRouteContent } from "./auth/private-route-content";
import { MyConsultsAndMatchesV2 } from "./consults-and-matching/MatchingV2/matching-v2";
import MatchQueue from "./consults-and-matching/MatchQueue";
import { Utilization } from "./dashboard/utilization/Utilization";
import { Flex, Stack } from "./design-system";
import { GroupTherapyPage } from "./groups/groups";
import { GroupTherapyDetailsPage } from "./groups/group-id/group-id";
import Library from "./library";
import RedirectComponent from "./redirect";
import { ClientProfilePage } from "./my-clients/routes/client-profile-page";
import { MyClientsPage } from "./my-clients/routes/my-clients-page/my-clients-page";
import PanelManagement from "./panel-management";
import SlotToolPage from "./slot-tool/root";
import Team from "./team";
import TimeOff from "./timeoff";
import MainNavigationMenuV2 from "./_layout/main-navigation-menu";
import { MbcProvider } from "./client/context";
import { GOOGLE_TAG_MANAGER_ID } from "@/api/constants";
import { useGetCurrentUser } from "@/app/client/use-get-current-user/use-get-current-user.api";
import { useGetConfig } from "@/app/api/use-get-config/use-get-config";
import { SkipLink } from "./_shared/SkipLink";
import { GOOGLE_CLIENT_ID } from "@/api/constants";
import { useShallowEqualSelector } from "./_helpers/redux";
import { optionsOperations } from "@/state/models/options";
import { useDispatch } from "react-redux";

import { Permissions } from "@/app/_helpers/permissions";
import { PrivacyKey } from "@/app/_helpers/constants";

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);

const WrappedUtilizationPane = withRouteSentryWrapper(
  Utilization,
  "utilization",
);
const WrappedLibrary = withRouteSentryWrapper(Library, "content_library");
const WrappedSlotToolPage = withRouteSentryWrapper(
  SlotToolPage,
  "availability_tool",
);
const WrappedTeam = withRouteSentryWrapper(Team, "team");
const WrappedTimeOff = withRouteSentryWrapper(TimeOff, "timeoff");
const WrappedMyConsultsAndMatchesV2 = withRouteSentryWrapper(
  MyConsultsAndMatchesV2,
  "my_consults_v2",
);
const WrappedMatchQueue = withRouteSentryWrapper(MatchQueue, "match_queue");
const WrappedPanelManagement = withRouteSentryWrapper(
  PanelManagement,
  "panel_management",
);
const WrappedMyClientsPage = withRouteSentryWrapper(
  MyClientsPage,
  "my-clients",
);
const WrappedClientProfilePage = withRouteSentryWrapper(
  ClientProfilePage,
  "client-profile",
);

const WrappedGroupTherapyPage = withRouteSentryWrapper(
  GroupTherapyPage,
  "group-therapy",
);
const WrappedGroupTherapyDetailsPage = withRouteSentryWrapper(
  GroupTherapyDetailsPage,
  "group-therapy-details",
);

const AppRouting = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useCarePlatform();
  const [cuser, doMangle] = useShallowEqualSelector((state) => [
    state.auth.currentUser,
    state.options.doMangle,
  ]);
  const { data: currentUser } = useGetCurrentUser();
  if (currentUser) {
    localStorage.setItem("isLoggedIn", "true");
  }
  const { data: config } = useGetConfig(
    currentUser && currentUser.id ? true : false,
  );

  //@ts-ignore
  const isHolisticAlertsBetaUser = Permissions.isHolisticAlertsBetaUser(
    cuser,
    config?.ehr_configuration[0],
  );
  const location = useLocation();
  const [cookies] = useCookies([PrivacyKey]);
  React.useEffect(() => {
    const isPrivacyCookieOn = !!cookies[PrivacyKey];
    if (isPrivacyCookieOn !== doMangle) {
      dispatch(optionsOperations.toggleMangle());
    }
  }, [location]);

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <CurrentUserContext.Provider value={currentUser}>
        <CurrentConfigContext.Provider
          // @ts-ignore
          value={{ ...config?.ehr_configuration[0] }}
        >
          <MbcProvider>
            <SkipLink />
            <Flex css={{ width: "100vw", height: "100vh" }}>
              <MainNavigationMenuV2
                isCollapsed={isCollapsed}
                setIsCollapsed={(v) => setIsCollapsed(v)}
              />

              <Stack
                css={{
                  flexGrow: 1,
                  backgroundColor: "$neutral2",
                  height: "100%",
                  overflowY: "scroll",
                  marginLeft: !isAuthenticated ? 0 : isCollapsed ? 60 : 220,
                }}
              >
                <SentryRoutes>
                  <Route
                    path="login"
                    element={
                      <AuthRouteContent>
                        <Login />
                      </AuthRouteContent>
                    }
                  />

                  <Route
                    path="availability"
                    element={
                      <PrivateRouteContent>
                        <RedirectComponent />
                      </PrivateRouteContent>
                    }
                  />

                  <Route
                    path="dashboard"
                    element={
                      <PrivateRouteContent>
                        <RedirectComponent />
                      </PrivateRouteContent>
                    }
                  />

                  <Route path="dashboard">
                    <Route
                      path=":clinicianId/utilization"
                      element={
                        <PrivateRouteContent>
                          <WrappedUtilizationPane />
                        </PrivateRouteContent>
                      }
                    />

                    <Route
                      path=":clinicianId/alliance-scores"
                      element={
                        <PrivateRouteContent>
                          <RedirectComponent />
                        </PrivateRouteContent>
                      }
                    />

                    <Route
                      path=":clinicianId/client-adherence"
                      element={
                        <PrivateRouteContent>
                          <RedirectComponent />
                        </PrivateRouteContent>
                      }
                    />

                    <Route
                      path=":clinicianId/client-attendance"
                      element={
                        <PrivateRouteContent>
                          <RedirectComponent />
                        </PrivateRouteContent>
                      }
                    />
                  </Route>

                  <Route
                    path="library"
                    element={
                      // TODO: replace IsResourceLibBetaUser with IsClinician post rollout
                      <PrivateRouteContent
                        permissions={[
                          "IsResourceLibCurator",
                          "IsResourceLibBetaUser",
                        ]}
                      >
                        <WrappedLibrary />
                      </PrivateRouteContent>
                    }
                  />

                  <Route
                    path="availability-tool"
                    element={
                      <PrivateRouteContent>
                        <WrappedSlotToolPage />
                      </PrivateRouteContent>
                    }
                  />

                  <Route
                    path="team"
                    element={
                      <PrivateRouteContent permissions={["IsClinicalLeader"]}>
                        <WrappedTeam />
                      </PrivateRouteContent>
                    }
                  />

                  <Route
                    path="time-off"
                    element={
                      <PrivateRouteContent
                        permissions={["IsClinicalLeader", "IsCareCoordinator"]}
                      >
                        <RedirectComponent />
                      </PrivateRouteContent>
                    }
                  />

                  <Route
                    path="matching"
                    element={
                      <PrivateRouteContent
                        permissions={[
                          "IsClinicalLeader",
                          "IsConsultClinician",
                          "IsRequeuer",
                          "IsPsychiatrist",
                        ]}
                      >
                        <WrappedMyConsultsAndMatchesV2 />
                      </PrivateRouteContent>
                    }
                  />

                  <Route
                    path="match-queue"
                    element={
                      <PrivateRouteContent
                        permissions={["IsClinicalLeader", "IsMatchingAdmin"]}
                      >
                        <WrappedMatchQueue />
                      </PrivateRouteContent>
                    }
                  />

                  <Route
                    path="panel-management"
                    element={
                      <PrivateRouteContent>
                        <WrappedPanelManagement />
                      </PrivateRouteContent>
                    }
                  />
                  <Route
                    path="consult/:clientId/consents"
                    element={<RedirectComponent />}
                  />

                  <Route path="my-clients">
                    <Route index element={<RedirectComponent />} />

                    <Route
                      path=":clientId/*"
                      element={<Navigate to="mbc" replace />}
                    />

                    <Route
                      path=":clientId/schedule"
                      key="WrappedClientProfilePage"
                      element={<RedirectComponent />}
                    />
                    <Route
                      path=":clientId/mbc"
                      key="WrappedClientProfilePage"
                      element={
                        isHolisticAlertsBetaUser ? (
                          <RedirectComponent />
                        ) : (
                          <PrivateRouteContent
                            permissions={[
                              "IsSchedulingUIBetaUser",
                              "IsSuperUser",
                              "IsClinician",
                              "IsCareCoordinator",
                            ]}
                          >
                            <WrappedClientProfilePage />
                          </PrivateRouteContent>
                        )
                      }
                    />

                    <Route
                      path="*"
                      element={<Navigate to="../my-clients" replace />}
                    />
                  </Route>

                  <Route path="groups">
                    <Route
                      index
                      element={
                        <PrivateRouteContent
                          permissions={["IsGroupTherapyUser"]}
                        >
                          <WrappedGroupTherapyPage />
                        </PrivateRouteContent>
                      }
                    />

                    {[":groupId/sessions/:sessionId/notes"].map((path) => (
                      <Route
                        path={path}
                        key="WrappedGroupTherapyPage"
                        element={
                          <PrivateRouteContent
                            permissions={["IsGroupTherapyUser"]}
                          >
                            <WrappedGroupTherapyDetailsPage />
                          </PrivateRouteContent>
                        }
                      />
                    ))}

                    {[
                      ":groupId/sessions/:sessionId/mbc",
                      ":groupId/clients",
                      ":groupId/clients/:clientId/mbc",
                      ":groupId/clients/:clientId/notes",
                      ":groupId/clients/:clientId/profile",
                    ].map((path) => (
                      <Route
                        path={path}
                        key="WrappedGroupTherapyV2Page"
                        element={
                          <PrivateRouteContent
                            permissions={["IsGroupTherapyV2User"]}
                          >
                            <WrappedGroupTherapyDetailsPage />
                          </PrivateRouteContent>
                        }
                      />
                    ))}

                    <Route
                      path="*"
                      element={<Navigate to="../groups" replace />}
                    />
                  </Route>

                  <Route
                    path="*"
                    element={<Navigate to="dashboard" replace />}
                  />
                </SentryRoutes>
              </Stack>
            </Flex>
          </MbcProvider>
        </CurrentConfigContext.Provider>
      </CurrentUserContext.Provider>
    </GoogleOAuthProvider>
  );
};

export function AppV2() {
  const queryClient = new QueryClient();

  return (
    <Sentry.ErrorBoundary>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="top-right" />
          <CookiesProvider defaultSetOptions={{ path: "/" }}>
            <AppRouting />
          </CookiesProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}
